import { Component } from "react";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

import ActionCreator from "./../data/ActionCreator";
import configuration from "../utils/Configuration";

export default class SignalR extends Component {
  constructor(props) {
    super(props);

    const hubConnection = new HubConnectionBuilder()
      .withUrl(configuration.value("signalRHubUrl"))
      .configureLogging(LogLevel.Information)
      .build();
    this.state = { hubConnection: hubConnection };

    console.log("SignalR ctor");
  }

  componentDidMount = () =>
    this.state.hubConnection
      .start()
      .then(() => {
        console.log("HubConnection started");
        this.state.hubConnection.on("newBundle", this.onPing);
      })
      .catch((error) =>
        console.log(`HubConnection start probably failed, ${error}`),
      );

  componentWillUnmount = () =>
    this.state.hubConnection
      .stop()
      .then(() => console.log("HubConnection stopped"))
      .catch((error) =>
        console(`Stopping HubConnection probably failed, ${error}`),
      );

  onPing = (bundle) => {
    console.log(bundle);
    ActionCreator.signalrPingReceive(bundle);
  };

  render = () => null;
}
