const config = {
  init: (fn) => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        window.___w = { ...config };
        fn();
      });
  },
  value: (key) => window.___w[key],
};

export default config;
