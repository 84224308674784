import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";
import configuration from "./utils/Configuration";
import { createRoot } from "react-dom/client";

TimeAgo.addDefaultLocale(en);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

configuration.init(() => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
