import ActionCreator from "./../data/ActionCreator";
import configuration from "../utils/Configuration";

class ApiPoller {
  constructor() {
    console.log("ApiPoller ctor");
  }

  poll = (configKey) => {
    let key = configKey ?? "latestBundledUrl";
    return fetch(configuration.value(key))
      .then((response) => response.json())
      .then((data) =>
        key.indexOf("Cache") !== -1
          ? ActionCreator.updateCacheEndpointValues(data)
          : ActionCreator.updateCurrentValues(data),
      )
      .catch((error) => console.log(error));
  };
}

const poller = new ApiPoller();
export const Poller = poller;
