import ACTION_TYPES from "./ACTION_TYPES";
import Dispatcher from "./Dispatcher";

class ActionCreator {
  constructor() {
    console.log("ActionCreator ctor");
  }

  signalrPingReceive = (d) =>
    Dispatcher.dispatch({ type: ACTION_TYPES.SIGNALR_PING, value: d });

  updateCacheEndpointValues = (d) => console.log("updateCacheEndpointValues");

  updateCurrentHumidity = (d) =>
    this.__dispatchUpdateCurrentValue(ACTION_TYPES.UPDATE_CURRENT_HUMIDITY, d);
  updateCurrentPressure = (d) =>
    this.__dispatchUpdateCurrentValue(ACTION_TYPES.UPDATE_CURRENT_PRESSURE, d);
  updateCurrentTemperature = (d) =>
    this.__dispatchUpdateCurrentValue(
      ACTION_TYPES.UPDATE_CURRENT_TEMPERATURE,
      d,
    );
  updateCurrentValues = (d) => {
    this.__dispatchUpdateCurrentValue(
      ACTION_TYPES.UPDATE_CURRENT_HUMIDITY,
      d.humidity,
    );
    this.__dispatchUpdateCurrentValue(
      ACTION_TYPES.UPDATE_CURRENT_PRESSURE,
      d.pressureQFF,
    );
    this.__dispatchUpdateCurrentValue(
      ACTION_TYPES.UPDATE_CURRENT_TEMPERATURE,
      d.temperature,
    );
    this.__dispatchUpdateCurrentValue(
      ACTION_TYPES.UPDATE_YESTERDAY_TEMPERATURES,
      d.calculatedValues.yesterday,
    );
  };

  __dispatchUpdateCurrentValue = (actionType, data) => {
    let action = { type: actionType, value: data };

    Dispatcher.dispatch(action);

    console.log(`${action.type}: ${JSON.stringify(action.value)}`);
  };
}

const actionCreator = new ActionCreator();
export default actionCreator;
