import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import DoOnIntervall from "./DoOnIntervall";

import UpdatedAgo from "./UpdatedAgo";

export default class TriggerPollButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      lastClicked: new Date(),
    };

    this.updateEnabledState = this.updateEnabledState.bind(this);
    this.getNewBundle = this.getNewBundle.bind(this);

    console.log("TriggerPollButton ctor");
  }

  componentDidMount = () =>
    this.props.pingStore.on("newBundle", this.getNewBundle);

  componentWillUnmount = () =>
    this.props.pingStore.removeListener("newBundle", this.getNewBundle);

  getNewBundle = () =>
    this.props.poller.poll().then(this.setState({ lastClicked: new Date() }));
  getNewMetar = () => this.props.poller.poll("metarCacheUrl");

  updateEnabledState = () => {
    const possibleRefreshIn = 90000;

    let ms = new Date().getTime() - this.state.lastClicked.getTime();
    let enabled = ms > possibleRefreshIn;
    let countdown = Math.round((possibleRefreshIn - ms) / 1000);
    this.setState({
      enabled: enabled,
      countdown: countdown > -1 ? `(${countdown})` : "",
    });
  };

  updateMetar = () => this.getNewMetar();

  render = () => (
    <>
      <DoOnIntervall action={this.updateEnabledState} intervall={1000} />
      <DoOnIntervall action={this.updateMetar} intervall={90000} />
      <button
        onClick={this.getNewBundle}
        style={{ padding: "8px" }}
        disabled={!this.state.enabled}
      >
        <FontAwesomeIcon icon={faSync} className="fa-style" />
        <UpdatedAgo date={new Date()} store={this.props.valueStore} />
        <span> {this.state.countdown}</span>
      </button>
    </>
  );
}
