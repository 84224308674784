import { EventEmitter } from "events";
import ACTION_TYPES from "./ACTION_TYPES";
import Dispatcher from "../data/Dispatcher";

class CurrentValueStore extends EventEmitter {
  constructor(actionType) {
    super();

    this.actionType = actionType;
    this.current = { value: "..." };

    console.log("CurrentValueStore ctor");
  }

  getValue = () => this.current;

  handleActions = (action) => {
    if (action.type === this.actionType) {
      this.current = action.value;
      this.emit("change");

      console.log(`${action.type}: ${JSON.stringify(action.value)}`);
    }
  };
}

export const currentPressureStore = new CurrentValueStore(
  ACTION_TYPES.UPDATE_CURRENT_PRESSURE,
);
export const currentTemperatureStore = new CurrentValueStore(
  ACTION_TYPES.UPDATE_CURRENT_TEMPERATURE,
);
export const currentHumidityStore = new CurrentValueStore(
  ACTION_TYPES.UPDATE_CURRENT_HUMIDITY,
);
export const yesterdayTemperaturesStore = new CurrentValueStore(
  ACTION_TYPES.UPDATE_YESTERDAY_TEMPERATURES,
);

Dispatcher.register(
  currentTemperatureStore.handleActions.bind(currentTemperatureStore),
);
Dispatcher.register(
  yesterdayTemperaturesStore.handleActions.bind(yesterdayTemperaturesStore),
);
Dispatcher.register(
  currentPressureStore.handleActions.bind(currentPressureStore),
);
Dispatcher.register(
  currentHumidityStore.handleActions.bind(currentHumidityStore),
);
