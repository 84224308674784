import React, { Component } from "react";
import "../App.css";

export default class CurrentValue extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
    this.getCurrentValue = this.getCurrentValue.bind(this);

    console.log("CurrentValue ctor");
  }

  componentDidMount = () => this.props.store.on("change", this.getCurrentValue);

  componentWillUnmount = () =>
    this.props.store.removeListener("change", this.getCurrentValue);

  getCurrentValue = () => {
    let value = this.props.store.getValue();
    this.setState({
      value: `${(Math.round(value[this.props.valueProp] * 10) / 10).toLocaleString("sv-se")}${this.props.unit ?? ""}`,
    });
  };

  render = () => <>{this.state.value}</>;
}
