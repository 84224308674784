import React, { Component } from "react";
import { Route, Link, Routes } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTemperatureLow,
  faTint,
  faCompressArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Poller } from "./utils/ApiPoller";

import {
  currentHumidityStore,
  currentPressureStore,
  currentTemperatureStore,
  yesterdayTemperaturesStore,
} from "./data/CurrentValueStore";
import { signalrPingStore } from "./data/SignalRPingStore";

import "./App.css";

import TriggerPollButton from "./components/TriggerPollButton";
import CurrentValue from "./components/CurrentValue";
import Temperature from "./components/Temperature";
import Pressure from "./components/Pressure";
import Humidity from "./components/Humidity";
import SignalR from "./components/SignalR";

export default class App extends Component {
  constructor() {
    super();
    console.log("App ctor");
  }

  componentDidMount = () => Poller.poll();

  componentWillUnmount() {}

  render = () => (
    <div className="App">
      <section>
        <h1>Weather @ Rynningeåsen</h1>
        <h2 style={{ marginBottom: 3 }}>
          <Link to="/">
            {" "}
            <FontAwesomeIcon icon={faTemperatureLow} className="fa-style" />
          </Link>
          <CurrentValue
            value="..."
            store={currentTemperatureStore}
            valueProp="temperature"
            unit="°C"
          />
        </h2>
        <h3 style={{ marginTop: 3 }}>
          yesterday: [
          <CurrentValue
            value="..."
            store={yesterdayTemperaturesStore}
            valueProp="minTemperature"
          />{" "}
          :{" "}
          <CurrentValue
            value="..."
            store={yesterdayTemperaturesStore}
            valueProp="avgTemperature"
          />{" "}
          :{" "}
          <CurrentValue
            value="..."
            store={yesterdayTemperaturesStore}
            valueProp="maxTemperature"
          />
          ]
        </h3>
        <h2>
          <Link to="/pressure">
            <FontAwesomeIcon icon={faCompressArrowsAlt} className="fa-style" />
          </Link>
          <CurrentValue
            value="..."
            store={currentPressureStore}
            valueProp="pressureQFF"
            unit=" hPa (QFF)"
          />
        </h2>
        <h2>
          <Link to="/humidity">
            <FontAwesomeIcon icon={faTint} className="fa-style" />
          </Link>
          <CurrentValue
            value="..."
            store={currentHumidityStore}
            valueProp="humidity"
            unit="%"
          />
        </h2>
        <p>
          <TriggerPollButton
            valueStore={currentTemperatureStore}
            poller={Poller}
            pingStore={signalrPingStore}
          />
        </p>
      </section>
      <hr />
      <section>
        <Routes>
          <Route path="/" element={<Temperature />} exact />
          <Route path="/pressure" element={<Pressure />} exact />
          <Route path="/humidity" element={<Humidity />} exact />
        </Routes>
        <p style={{ color: "lightgray" }}>Build 20240730.1</p>
      </section>
      <SignalR />
    </div>
  );
}

