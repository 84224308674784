import { Component } from "react";

export default class DoOnIntervall extends Component {
  constructor(props) {
    super(props);

    console.log("DoOnIntervall ctor");
  }

  componentDidMount = () =>
    (this.__intervall = setInterval(
      () => this.props.action(),
      this.props.intervall,
    ));

  componentWillUnmount = () => clearInterval(this.__intervall);

  render = () => null;
}
