import IFrame from "./IFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons";

const comp = () => (
  <>
    <h2>
      <FontAwesomeIcon icon={faCompressArrowsAlt} className="fa-style" />
      Lufttryck (hPa)
    </h2>
    <IFrame
      src={
        "https://api.thingspeak.com/channels/693482/charts/2?title=Nyligen&width=auto&height=400&average=10&dynamic=true&type=spline&bgcolor=f8f8f8"
      }
    />
    <IFrame
      src={
        "https://api.thingspeak.com/channels/693482/charts/2?title=Senaste+dygnet&width=auto&height=400&days=1&average=30&dynamic=true&type=spline&bgcolor=f0f0f0"
      }
    />
    <IFrame
      src={
        "https://api.thingspeak.com/channels/693482/charts/2?title=Senaste+veckan&width=auto&height=400&days=7&average=240&dynamic=true&type=spline&bgcolor=e8e8e8"
      }
    />
  </>
);

export default comp;
