import { EventEmitter } from "events";
import ACTION_TYPES from "./ACTION_TYPES";
import Dispatcher from "./Dispatcher";

class SignalRPingStore extends EventEmitter {
  constructor() {
    super();

    this.current = { at: "..." };
    console.log("SignalRPingStore ctor");
  }

  getValue = () => this.current;

  handleActions = (action) => {
    if (action.type === ACTION_TYPES.SIGNALR_PING) {
      this.current = action.value;
      this.emit("newBundle");

      console.log(`${action.type}: ${JSON.stringify(action.value)}`);
    }
  };
}

export const signalrPingStore = new SignalRPingStore();

Dispatcher.register(signalrPingStore.handleActions.bind(signalrPingStore));
