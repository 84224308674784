import React, { Component } from "react";
import ReactTimeAgo from "react-time-ago";

export default class UpdatedAgo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
    };

    this.updatedAt = this.updatedAt.bind(this);

    console.log("UpdatedAgo ctor");
  }

  componentDidMount = () => this.props.store.on("change", this.updatedAt);

  componentWillUnmount = () =>
    this.props.store.removeListener("change", this.updatedAt);

  updatedAt = () => {
    let value = this.props.store.getValue();
    this.setState({
      date: new Date(value.at),
    });
  };

  render = () => (
    <>
      Updated <ReactTimeAgo date={this.state.date} locale="en-US" />
    </>
  );
}
